<template>
  <v-container>
    <v-card>
      <v-card-title> Contact Info </v-card-title>
      <v-divider></v-divider>
      <v-row>
        <v-col>
          <v-text-field label="Email" outlined></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6"> <v-text-field label="First name" outlined></v-text-field> </v-col>
        <v-col cols="6"> <v-text-field label="Last name" outlined></v-text-field> </v-col>
      </v-row>
      <v-row>
        <v-col cols="6"> <v-text-field label="Phone" outlined></v-text-field> </v-col>
        <v-col cols="6"> <v-text-field label="Company (Optional)" outlined></v-text-field> </v-col>
      </v-row>
      <v-row>
        <v-col cols="6"> <v-text-field label="Street" outlined></v-text-field> </v-col>
        <v-col cols="6"> <v-text-field label="Country" outlined></v-text-field> </v-col>
      </v-row>
      <v-row>
        <v-col cols="6"> <v-text-field label="City" outlined></v-text-field> </v-col>
        <v-col cols="6"> <v-text-field label="Postal code" outlined></v-text-field> </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'ContactInfoCard',
}
</script>

<style></style>
